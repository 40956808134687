


























import Vue from "vue";
import { Product } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import ProductItem from "../components/ProductItem.vue";
import CreateProduct from "../components/CreateProduct.vue";

@Component({ components: { ProductItem, CreateProduct } })
export default class StripeProducts extends Vue {
  @Getter("products/getting") getting!: boolean;
  @Getter("products/retrieved") retrieved!: boolean;
  @Getter("products/products") products!: Product[];
  @Action("products/get") getProducts!: Function;
  @Action("displaySnackbar") displaySnackbar!: Function;

  created() {
    if (!this.getting && !this.retrieved) this.getProducts();
  }
}
