import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold text-h4"},[_vm._v(" "+_vm._s(_vm.$t("admin.products"))+" ")]),_c(VSpacer),_c('CreateProduct'),_c(VBtn,{staticClass:"ml-4",attrs:{"icon":"","loading":_vm.getting,"disabled":_vm.getting},on:{"click":_vm.getProducts}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)],1),_c(VCol,{attrs:{"cols":"12"}},[(_vm.retrieved)?_c(VList,_vm._l((_vm.products),function(item,i){return _c('ProductItem',{key:i,attrs:{"item":item}})}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }